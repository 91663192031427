import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { PrivacyPage } from 'components/PrivacyPage'

const PrivacyPolicyPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <PrivacyPage />
    </EllicotLayout>
  )
}

export default PrivacyPolicyPage
